import React from "react";
import './ResumeContact.css';

const ResumeContact = ({ location, phone, email }) => {
  return (
    <section className="box">
      <h2>
        <i className="fas fa-bullseye ico"></i> Contact
      </h2>
      <div className="contact-item">
        <div className="icon">
          <span className="fas fa-map-marker fa-fw"></span>
        </div>
        <div className="title">
          {location.address} <br />
          {location.postalCode} {location.city}
        </div>
      </div>
      <div className="contact-item">
        <div className="icon">
          <span className="fas fa-phone fa-fw"></span>
        </div>
        <div className="title only">{phone}</div>
      </div>
      <div className="contact-item">
        <div className="icon">
          <span className="fas fa-envelope fa-fw"></span>
        </div>
        <div className="title only ">
            {email}
        </div>
      </div>
    </section>
  );
};

export default ResumeContact;
