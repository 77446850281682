import React from "react";
import './ResumeHeader.css'


const ResumeHeader = ({ name, label }) => {
  return (
    <div className="header">
      <h1>{name}</h1>
      <h2>{label}</h2>
    </div>
  );
};

export default ResumeHeader;
