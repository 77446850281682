import React from "react";
import Moment from "react-moment";
import "moment/locale/fr";
import "./ResumeWork.css";

const ResumeWork = ({ works }) => {
  return (
    <section className="box jobs">
      <h2>
        <i className="fas fa-suitcase ico"></i> Expérience professionnelle
      </h2>
      {works.map(
        ({
          company,
          location,
          position,
          startDate,
          endDate,
          summary,
          highlights,
        }) => {
          return (
            <div className="job" key={startDate}>
              <div className="row">
                <div className="details">
                  <div className="where">
                    {company}
                    <div className="location">
                      <span className="fas fa-map-marker fa-fw"></span>{" "}
                      {location}
                    </div>
                  </div>
                  <div className="profession">
                    {position}
                    <div className="year">
                      <i className="far fa-calendar"></i>{" "}
                      <Moment locale="fr" format="MMMM YYYY">
                        {startDate}
                      </Moment>{" "}
                      -{" "}
                      {endDate ? (
                        <Moment locale="fr" format="MMMM YYYY">
                          {endDate}
                        </Moment>
                      ) : (
                        "Présent"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="job-details col-xs-11">
                  <div className="description">
                    {summary}
                    {highlights.map(({ name, tasks, keywords }) => {
                      return (
                        <div className="highlights" key={name}>
                          <span className="highlight">{name}</span>
                          <ul className="tasks">
                            {tasks.map((task) => (
                              <li key={task}>{task}</li>
                            ))}
                          </ul>
                          <div className="env">
                            {keywords.map((keyword) => (
                              <span key={keyword} className="skill badge">
                                {keyword}
                              </span>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      )}
    </section>
  );
};

export default ResumeWork;
