import React from "react";
import Header from "./ResumeHeader";
import Contact from "./ResumeContact";
import Skills from "./ResumeSkills";
import Languages from "./ResumeLanguages";
import Education from "./ResumeEducation";
import Work from "./ResumeWork";
import data from "./resume.json";
import './Resume.css'

const Resume = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="container">
      <Header {...data.basics} />
      <div class="work">
          <Work works={data.work} />
      </div>
      <div class="other">
        <Contact {...data.basics} />
        <Skills skills={data.skills} />
        <Languages languages={data.languages} />
        <Education education={data.education} />
      </div>
    </div>
  );
});

export default Resume;
