import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Resume from "./Resume";

import "./App.css";

function App() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "body-print",
    documentTitle: "CV - Nicolas ESCURE"
  });
  return (
    <>
      <button className="btn-export" onClick={handlePrint}>
        <i className="fas fa-file-pdf"></i>
      </button>

      <Resume ref={componentRef} />
    </>
  );
}

export default App;
