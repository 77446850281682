import React from "react";
import Moment from "react-moment";
import "./ResumeEducation.css";

const ResumeEducation = ({ education }) => {
  return (
    <section className="box">
      <h2>
        <i className="fas fa-university ico"></i> Formation
      </h2>
      <div className="educationlist">
        {education.map(
          ({ startDate, endDate, institution, studyType, area, courses }) => {
            return (
              <div className="education">
                <div className="year">
                  <Moment locale="fr" format="YYYY">
                    {startDate}
                  </Moment>{" "}
                  -{" "}
                  <Moment locale="fr" format="YYYY">
                    {endDate}
                  </Moment>
                </div>
                <div className="description">
                  <h3>{institution}</h3>
                  <p>
                    <i className="fas fa-graduation-cap ico"></i> {studyType}
                  </p>
                  <p>{area}</p>
                    {courses.map((course) => (
                      <div key={course} className="course">{course}</div>
                    ))}
                </div>
              </div>
            );
          }
        )}
      </div>
    </section>
  );
};

export default ResumeEducation;
