import React from "react";
import './ResumeLanguages.css'

const ResumeLanguages = ({ languages }) => {
  return (
    <section className="box">
      <h2>
        <i className="fas fa-language ico"></i> Langues
      </h2>
      <ul className="list-group">
        {languages.map(({ language, fluency }) => (
          <li key={language} className=" list-group-item">
            {language}
            <span className="language badge">{fluency}</span>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ResumeLanguages;
