import React from "react";
import './ResumeSkills.css'

const ResumeSkills = ({ skills }) => {
  return (
    <section className="box">
      <h2>
        <i className="fas fa-tasks ico"></i> Compétences
      </h2>
      {skills.map(({ name, keywords }) => {
        return (
          <div key={name} className="skills">
            <div className="item-skills">{name}</div>
            <div className="">
              {keywords.map((skill) => {
                return <span key={skill} className="skill badge">{skill}</span>;
              })}
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default ResumeSkills;
